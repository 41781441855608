const searchParamfix = (params) => {
  let { searchInput, searchField, op, filterField, filterValue } = params;
  searchInput = searchInput || [];
  searchField = searchField || [];
  op = op || [];

  if (typeof searchInput === "string") {
    searchInput = [searchInput];
  }

  if (typeof searchField === "string") {
    searchField = [searchField];
  }

  if (typeof op === "string") {
    op = [op];
  }

  op.splice(0, 0, "");
  let removeId = 0;
  searchInput = searchInput.filter((v, k) => {
    if (v != null && v.trim() != "") {
      return 1;
    } else {
      searchField.splice(k - removeId, 1);
      op.splice(k - removeId, 1);
      removeId++;
    }
  });
  op.splice(0, 1);

  let searchForm = {
    ...params,
    searchField: searchField,
    searchInput: searchInput.map(
      Function.prototype.call,
      String.prototype.trim
    ),
    op: op,
    filterField: filterField,
    filterValue: filterValue,
  };

  Object.keys(searchForm).map((key) => {
    if (
      searchForm[key] == null ||
      (typeof searchForm[key] === "string" && searchForm[key].trim() == "")
    ) {
      delete searchForm[key];
    }
  });

  return searchForm;
};

export default {
  searchParamfix,
};
