import React from "react";
import { autobind, log, trace, withStyles } from "react-decoration";

class KeyboardInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //VKI_attach(this.refs.searchInput);
  }

  @autobind
  val(value) {
    if (value != null) {
      this.refs.searchInput.value = value;
    } else {
      return this.refs.searchInput.value;
    }
  }

  render() {
    let { autoComplete = "on", id = "" } = this.props;
    return (
      <input
        type="text"
        ref="searchInput"
        id={id}
        title={this.props.title}
        name={this.props.name}
        onChange={this.props.onChange}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        placeholder={this.props.placeholder}
        onKeyPress={this.props.onKeyPress}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        autoComplete={autoComplete}
        className="keyboardInput"
      />
    );
  }
}

export default KeyboardInput;
