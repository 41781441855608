import React from "react";
import LightBox from "components/common/LightBox";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import autobind from "react-decoration";
import qs from "query-string";
import { navigate } from "gatsby";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";

const getIntegrationDBChooseList = gql`
  query getIntegrationDBChooseList {
    getIntegrationDBChooseList {
      rootClassItemList {
        rootClass {
          sn
          isClose
        }
        subClassItemList {
          sn
          hyintID
          isClose
          num
          dbItemList {
            dbID
            ebName
            enEbName
            hyintID
            url
            maintain
            gIsQuery
            fullTextNo
            eIsQuery
          }
        }
      }
    }

    getRecommendClass{
      dbID
      ebName
      enEbName
      hyintID
      url
      maintain
      gIsQuery
      fullTextNo
      eIsQuery
    }
  }
`;

let defaultItems = [];

@LightBox
class IntegrationChooseLightBox extends React.Component {
  constructor(props) {
    super(props);
    let listType = this.props.appStore.globalConfig["jumperrwd.integration.nowChooseHidden"] === "1" ? "default" : "now";
    this.state = {
      listType: listType,
      choose: {},
      subSnAccrodion: [],
      defaultItems: [],
      auth: props.readerStore.auth,
      refetch: null,
    }
    this.getDefaultRecommend();
  }

  async componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      if (this.state.refetch !== null) {
        await this.state.refetch();
        await this.getDefaultRecommend(true);
      }
    }
  }

  async getDefaultRecommend(reset){
    let params = qs.parseUrl(window.location.href).query;    
    let defaultItems = [];
    let choose = {};
    
    let { db, searchBlockType } = params; 
    db = db || [];
    if (searchBlockType !== "integration") {
      db = [];
    }
    if (typeof db === 'string') {
      db = [db];
    }

    await client.jumperrwdClient.query({
      query: gql`
        query getRecommendClass($form:IntegrationSearchForm){
          recommendItems:getRecommendClass{
            dbID
            ebName
            enEbName
            hyintID
            url
            maintain
            gIsQuery
            fullTextNo
            eIsQuery
          }

          selectDB:getSelectedDB(Input: $form){
            dbID
            ebName
            enEbName
            hyintID 
          }
        }
      `,
      variables: {
        form:{ db }
      }
    }).then((res)=>{     
      let subSnAccrodion = [];
      let pushSubSnAccrodion = false;
      const { recommendItems,selectDB } = res.data;
      recommendItems.forEach((item)=>{
        const { dbID,hyintID,ebName,enEbName } = item;
        const subSn = "default";
        defaultItems.push({dbID,subSn,hyintID,ebName,enEbName})
        if(reset||db.length==0){
          choose[dbID] = {dbID,subSn:"default",hyintID,ebName,enEbName};
          pushSubSnAccrodion = true;
        }
      })

      if(pushSubSnAccrodion) {
        subSnAccrodion.push("default");
      }

      if(!reset){
        selectDB.forEach((item)=>{
          const { dbID,hyintID,ebName,enEbName } = item;
          choose[dbID] = {dbID,subSn:"selected",hyintID,ebName,enEbName};     
        })
      }

      this.setState({
        choose,
        subSnAccrodion,
        defaultItems
      })
    });
  }

  handler = (e,item) => {
    let { subSnAccrodion,choose } = this.state;
    const {dbID,subSn,hyintID,ebName,enEbName} = item;
    if(e.target.checked){
      if(hyintID !=""){
        choose[dbID] = item;
      }
      let accordion_content = e.target.closest(".accordion_content")
      if(accordion_content!=null){
        let isALL = accordion_content.querySelectorAll("input[type=checkbox]:checked").length == accordion_content.querySelectorAll("input[type=checkbox]").length;
        if(isALL){
          subSnAccrodion.push(subSn)
        }
      }
    }else{
      const pointer = subSnAccrodion.indexOf(subSn);
      subSnAccrodion.splice(pointer,1)
      delete choose[dbID];
    }   
    this.setState({choose,subSnAccrodion})
  }

  checkedChildren = (e,subSn,dbItemList) => {
    let { t, i18n } = this.props;
    let { language } = i18n;
    let {subSnAccrodion,choose} = this.state;
    const pointer = subSnAccrodion.indexOf(subSn);
    if(pointer > -1){
      subSnAccrodion.splice(pointer,1)
    }else{
      subSnAccrodion.push(subSn);
    }

    
    dbItemList.forEach((item)=>{      
      const {dbID,hyintID,ebName,enEbName} = item;
      if(e.target.checked){
        if(hyintID !=""){
          choose[dbID] = {dbID,subSn,hyintID,ebName,enEbName};
        }
      }else{
        delete choose[dbID]
      }
    })
    
    this.setState({choose,subSnAccrodion});
  }


  toggleAccrodion = (e) => {
    e.persist();
    e.target.classList.toggle("turnicon");
    if(e.target.classList.contains("turnicon")){
      e.target.nextElementSibling.style.display = ""
    }else{
      e.target.nextElementSibling.style.display = "none"
    }
    
  }


  collapseNode = (e) => {
    e.persist();
    let btngrp = e.target.closest(".btn_grp");
    let scroll_content = btngrp.nextElementSibling;    
    let accordion_content = scroll_content.querySelectorAll(".accordion_content");    
    accordion_content.forEach((e1)=>{e1.style.display = "none"})
    scroll_content.querySelectorAll(".btn_switch")
    .forEach((e1)=>{e1.classList.remove("turnicon");})
    e.target.style.display = "none";
    btngrp.querySelector(".expand").style.display = "";
  }

  expandNode = (e) => {
    e.persist();
    let btngrp = e.target.closest(".btn_grp");
    let scroll_content = btngrp.nextElementSibling;    
    let accordion_content = scroll_content.querySelectorAll(".accordion_content");    
    accordion_content.forEach((e1)=>{e1.style.display = ""})
    scroll_content.querySelectorAll(".btn_switch")
    .forEach((e1)=>{e1.classList.add("turnicon");})
    e.target.style.display = "none";
    btngrp.querySelector(".collapse").style.display = "";
  }

  cancelAllDefault = () => {
    let { subSnAccrodion,choose ,defaultItems} = this.state;
    let sIndex = subSnAccrodion.indexOf("default");
    subSnAccrodion.splice(sIndex,1)
    this.setState({subSnAccrodion,choose})    

    defaultItems.forEach((item,key)=>{
      const { dbID,subSn,hyintID } = item;
      delete choose[dbID]     
    })

    this.setState({subSnAccrodion,choose})
  }

  cancelAll = (subClassItemList) => {
    if(subClassItemList == null){
      return;
    }
    let { subSnAccrodion,choose } = this.state;
    subClassItemList.map((subClassItem) => {
      subClassItem.dbItemList.forEach(
        (dbItem) => {
          let {dbID} = dbItem;
          delete choose[dbID]                                        
        }
      )
      let sIndex = subSnAccrodion.indexOf(subClassItem.sn);
      subSnAccrodion.splice(sIndex,1)
    })
    this.setState({subSnAccrodion,choose})
  }

  getHyIntID = () => {
    const { choose } = this.state;
    let hyintID = [];
    Object.keys(choose).forEach(key=>{      
      if(choose[key].hyintID!=null&&choose[key].hyintID!=""){     
        hyintID.push(choose[key].hyintID)
      }
    })
    return hyintID;
  }

  submit = () => {
    const { close } = this.props;
    const { choose } = this.state;
    if( Object.keys(choose).length == 0 ){
      alert(this.props.t("jumperrwd.decentralized.pleaseChooseDB"))
      return;
    }
    close();
  }

  cancelModal = () => {
    const { close } = this.props;
    const { choose } = this.state;
    if( Object.keys(choose).length == 0 ){
      this.getDefaultRecommend();
    }
    close();
  }

  render() {
    let { t, i18n , close, appStore}  = this.props;
    let { listType,choose,subSnAccrodion,defaultItems } = this.state;
    let { language } = i18n;
    const isChinese = this.props.i18n.language == "zh";
    let nowChooseHidden = appStore.globalConfig["jumperrwd.integration.nowChooseHidden"] === "1";

    let chooseBlock = Object.keys(choose).map((key)=>{
      const {dbID,subSn,hyintID,ebName,enEbName} = choose[key];
      let dbName = isChinese || (!isChinese&&enEbName=="") ? ebName : enEbName;
      return (
        <li>
          <input
            type="checkbox"
            tabIndex="0"
            id={"chooseDB_"+dbID}
            value={dbID}
            checked={dbID in choose}
            onClick={(e)=>{this.handler(e,{dbID,subSn,hyintID,ebName,enEbName})}}
          />
          <label htmlFor={"chooseDB_"+dbID}>
            <Link href={`/sendURLApiV3?dbid=${dbID}`} target="_BLANK">{dbName}</Link>
          </label>
        </li>
      )
    })
    
    defaultItems = [];

    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query query={getIntegrationDBChooseList} fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <center>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </center>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }
              if (data.getIntegrationDBChooseList) {
                let { getIntegrationDBChooseList:{rootClassItemList} ,getRecommendClass} = data;
                getRecommendClass.forEach((item)=>{
                  const { dbID ,hyintID,ebName,enEbName} = item;
                  defaultItems.push({dbID,subSn:"default",hyintID,ebName,enEbName})
                })
                
                let defaultBlock = defaultItems.map((item,key)=>{
                  const {dbID,subSn,hyintID,ebName,enEbName} = defaultItems[key];
                  let dbName = isChinese || (!isChinese&&enEbName=="") ? ebName : enEbName;
                  return (
                    <li>
                      <input
                        type="checkbox"
                        tabIndex="0"
                        id={"defaultChooseDB_"+dbID}
                        value={dbID}
                        checked={dbID in choose}
                        onClick={(e)=>{this.handler(e,{dbID,subSn,hyintID,ebName,enEbName})}}
                      />
                      <label htmlFor={"defaultChooseDB_"+dbID}>
                        <Link href={`/sendURLApiV3?dbid=${dbID}`} target="_BLANK">{dbName}</Link>
                      </label>
                    </li>
                  )
                })

                return (
                  <>
                    <div className="tabSet">
                      <ul className="tabs">
                        {!nowChooseHidden && <li className={listType === "now" ? "active" : ""}>
                          <a
                            tabIndex="0"
                            onClick={() => {
                              this.setState({ listType: "now" });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setState({ listType: "now" });
                              }
                            }}>
                            {t("jumperrwd.common.nowSelected")}
                          </a>
                        </li>}
                        <li className={listType === "default" ? "active" : ""}>
                          <a
                            tabIndex="0"
                            onClick={() => {
                              this.setState({ listType: "default" });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setState({ listType: "default" });
                              }
                            }}>
                            {t("jumperrwd.common.defaultRecommend")}
                          </a>
                        </li>
                        {rootClassItemList && rootClassItemList.map((rootClassItem) => {
                          let sn = rootClassItem.rootClass.sn;
                          return (
                            <li className={listType === sn ? "active" : ""}>
                              <a
                                tabIndex="0"
                                onClick={() => {
                                  this.setState({
                                    listType: sn,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.setState({
                                      listType: sn,
                                    });
                                  }
                                }}>
                                {t("jumperrwd.class.name." + sn)}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="tab-content">                      
                      
                      <section style={{ height:"100%", display:listType == "now"?"":"none"}}>
                        <div className="btn_grp">
                          <button type="button" tabIndex="0" onClick={()=>{this.setState({subSnAccrodion:[],choose:{}})}}> 
                            {t("jumper.common.uncheckall")}
                          </button>
                          <button type="button" tabIndex="0" onClick={this.getDefaultRecommend.bind(this,true)}> 
                            {t("jumper.common.defaultvalue")}
                          </button>
                        </div>      
                        <div className="scroll_content">
                          <ul>
                            {chooseBlock}
                          </ul>
                        </div>  
                      </section>   

                      <section style={{ height:"100%", display:listType == "default"?"":"none"}}>
                        <div className="btn_grp">
                          <button type="button" tabIndex="0" onClick={()=>{ this.cancelAllDefault(defaultItems)}}> 
                            {t("jumper.common.uncheckall")}
                          </button>
                        </div>       
                        <div className="scroll_content">
                          <ul>
                            <li className="accordion_header" >
                              <input
                                type="checkbox"
                                tabIndex="0"
                                id="sysRecommendResource"
                                value={"default"}
                                checked={subSnAccrodion.indexOf("default")>-1}
                                onClick={(e)=>{this.checkedChildren(e,"default",defaultItems)}}
                              />
                              <label htmlFor="sysRecommendResource">
                                {t("jumperrwd.common.sysRecommendResource")}
                              </label>
                              <button
                                type="button"
                                tabIndex="0"
                                className="btn_switch turnicon" onClick={this.toggleAccrodion}
                                style={{ fontSize: "0px" }}>Toggle accrodion</button>
                              <div className="accordion_content">
                                <ul>
                                  {defaultBlock}
                                </ul>
                              </div> 
                            </li>    
                          </ul>   
                        </div>   
                      </section>   

                      {rootClassItemList &&
                        rootClassItemList.map(
                          (rootClassItem) => {
                            let sn = rootClassItem.rootClass.sn;
                            let { subClassItemList } = rootClassItem;
                            let isAllClose = rootClassItem.rootClass.isClose === 1;
                            return (         
                              <>   
                                <div className="btn_grp" style={{ display:listType == sn?"":"none"}}>
                                  <button type="button" tabIndex="0" className="collapse" style={{display:isAllClose?"none":""}} onClick={this.collapseNode}>
                                    {t("jumper.common.closeall")}
                                  </button>
                                  <button type="button" tabIndex="0" className="expand" style={{display:isAllClose?"":"none"}} onClick={this.expandNode}>
                                    {t("jumper.common.openall")}
                                  </button>
                                  <button type="button" tabIndex="0" onClick={()=>{ this.cancelAll(subClassItemList)}}> 
                                    {t("jumper.common.uncheckall")}
                                  </button>
                                </div>                  
                                <div className="scroll_content" style={{ display:listType == sn?"":"none"}}>                                 
                                  <ul>
                                  {subClassItemList !== null &&
                                    subClassItemList.map((subClassItem) => {
                                      let subSn = subClassItem.sn;
                                      let display = listType === sn ? "" :"none"
                                      return (
                                        <li className="accordion_header" style={{display}}>
                                          <input
                                            type="checkbox"
                                            tabIndex="0"
                                            id={"class_" + subSn}
                                            value={subSn}
                                            checked={subSnAccrodion.indexOf(subSn)>-1}
                                            onClick={(e)=>{this.checkedChildren(e,subSn,subClassItem.dbItemList)}}
                                          />
                                          <label htmlFor={"class_" + subSn}>
                                            {t("jumperrwd.class.name." + subSn)}
                                          </label>
                                          <button
                                            type="button"
                                            tabIndex="0"
                                            className={isAllClose || subClassItem.isClose === 1 ? "btn_switch" : "btn_switch turnicon"} onClick={this.toggleAccrodion}></button>
                                          <div className="accordion_content" style={{ display: isAllClose || subClassItem.isClose === 1 ? "none" : "" }}>
                                            <ul>
                                              {subClassItem.dbItemList.map(
                                                (dbItem) => {
                                                  let {dbID,hyintID,ebName,enEbName} = dbItem;
                                                  let dbName = isChinese || (!isChinese&&enEbName=="") ? ebName : enEbName;
                                                  return (
                                                    <li>
                                                      {hyintID !== "" && (
                                                        <input
                                                          type="checkbox"
                                                          tabIndex="0"
                                                          id={"db_" + dbID}
                                                          value={dbID}
                                                          checked={dbID in choose}
                                                          onClick={(e)=>{this.handler(e,{dbID,subSn,hyintID,ebName,enEbName})}}
                                                        />
                                                      )}
                                                      <label htmlFor={hyintID !== "" ? "db_" + dbID : ""}>
                                                        <Link href={`/sendURLApiV3?dbid=${dbID}`} target="_BLANK">
                                                          {dbName}
                                                        </Link>
                                                      </label>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </li>
                                      );
                                    })}  
                                  </ul>                                
                                </div>
                              </>
                            );
                        
                          }
                        )
                      }
                    </div>
                    <p className="note">
                      {ReactHtmlParser(t("jumper.common.hyint.windows.tips"))}
                    </p>
                    <div className="btn_grp">
                      <button className="btn btn-reset" tabIndex="0" onClick={this.cancelModal}>{t("jumperrwd.common.close")}</button>
                      <button className="btn btn-submit" tabIndex="0" onClick={this.submit}>{t("jumperrwd.common.confirm")}</button>
                    </div>
                  </>
                );
              }

              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default IntegrationChooseLightBox;
